import { request } from '@midwayjs/hooks/request'


export  function getPaient (...args) {
  return request({
    url: '/api/patient/getPaient',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "lambda-patient-getpaient",
  "gateway": "http",
  "functionGroup": "highmyopia-report"
}
  })
}

