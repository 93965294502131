import { isNullOrUnDef } from "@/apis/utils"

export function useValidator() {
  const validatorMobile = {
    validator: (value) => /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value),
    message: '请输入正确的手机号码'
  }

  const validatorRequired = {
    validator: (value) => !isNullOrUnDef(value),
    message: '不能为空'
  }
  
  return { validatorRequired, validatorMobile }
}