// @ts-ignore 这里会报has no exported member 'ref'
import { computed, ref } from 'vue'

export function useCountDown() {
  const countdownNum = ref(60);

  const isCountdowning = computed(() => {
    return countdownNum.value !== 60
  })

  function onCountDown() {
    if (countdownNum.value === 0) {
      countdownNum.value = 60
      return
    }
    setTimeout(() => {
      countdownNum.value -= 1
      onCountDown()
    }, 1000);
  }

  return { countdownNum, isCountdowning, onCountDown }
}