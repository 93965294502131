import { request } from '@midwayjs/hooks/request'


export  function smsSend (...args) {
  return request({
    url: '/api/sms/smsSend',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "lambda-sms-smssend",
  "gateway": "http",
  "functionGroup": "highmyopia-report"
}
  })
}

export  function smsValidate (...args) {
  return request({
    url: '/api/sms/smsValidate',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "lambda-sms-smsvalidate",
  "gateway": "http",
  "functionGroup": "highmyopia-report"
}
  })
}

